<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
      <ProductItem
        v-for="product in getProducts"
        :key="product._id"
        :product="product"
      />
    </div>
    <ModalViewProduct />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductItem from "./components/ProductItem";
import ModalViewProduct from "./components/modalViewProduct.vue";
export default {
  data() {
    return {
      options: [
        {
          nome: 'Cadastrar Produto',
          action: () => this.openModalViewProduct({})
        }
      ]
    }
  },
  components: {
    ProductItem,
    ModalViewProduct
  },
  computed: {
    ...mapGetters(["getProducts"])
  },
  methods: {
    ...mapActions(["listProducts", "openModalViewProduct", "listSystemActions"])
  },
  created() {
    this.listProducts();
    this.listSystemActions();
  }
};
</script>
