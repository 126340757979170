<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="getModalViewProduct"
  >
    <v-card dark class="expande-horizontal wrap">
      <div class="expande-horizontal px-6 py-3">
        <v-btn dark @click="closeModalViewProduct" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt;" class="fonte white--text">
          Produto
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="closeModalViewProduct">
          <v-icon color="red darken-3">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome do produto
              </span>
              <v-text-field
                autofocus
                v-model="getProduct.name"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Descrição
              </span>
              <v-text-field
                v-model="getProduct.description"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                :color="$theme.primary"
              ></v-text-field>
            </v-flex>
            
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Preço em tokens
              </span>
              <v-text-field
                @keyup.enter="validate"
                v-model="getProduct.token_price"
                :rules="[v=> !!v || 'preencha aqui']"
                dense
                solo-inverted
                flat
                v-mask="['#', '##', '###', '####']"
                :color="$theme.primary"
                label="ex: 200"
              ></v-text-field>
            </v-flex>
            
            <!-- <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Ações a tomar
              </span>
              <v-select
                :items="getSystemActions"
                label="Escolha as ações"
              ></v-select>
            </v-flex> -->
            
            <v-flex class="px-7 pb-6" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Imagens do produto
              </span>
              <SendArchive />
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="validate" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SendArchive from '@/apps/shared/components/ModalSendArchive'
export default {
  components: {
      SendArchive
  },
  computed: {
    ...mapGetters(["getModalViewProduct", "getProduct", "getSystemActions"])
  },
  methods: {
    ...mapActions(["saveProduct", "closeModalViewProduct"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.saveProduct(this.getProduct);
      }
    }
  }
};
</script>
